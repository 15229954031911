import * as React from 'react';
import {apiHost} from '../../../config';
import { frequency, timeArray } from '../../const';
import { Button, Modal, Form, TextArea, Input, Dropdown, Checkbox, Icon, List } from 'semantic-ui-react';
import ReactS3Uploader from 'react-s3-uploader';
import {ViewFileModal} from './viewFormModal';
import { PopUpModal } from './popUpModal';
import Loading from '../Loading';
import {DeleteModal} from "../../components/Setup/deleteModal";

let progressCountVar: number=0;

export namespace AlertFormModal {
  export interface Props{
    action: string,
    addAlert: object,
    editAlert: object,
    testAlert: object,
    isLoad: boolean,
    hideForm:()=>void,
    updateAlert:(object:object)=>void,
    saveAlert:(object:object)=>void,
    testAlertQuery:(object:object)=>void,
    practices: any,
    notificationGroups : any
    practiceList: any[]
    practiceOTDList: any[]
    carrierList: any,
    carrierPrimaryList: any,
    patientValidationStatus: any
  }

  export interface State {
    progressCountVar: number,
    addAlert: object,
    editAlert: object,
    openFileModal: boolean,
    showPopUp: boolean,
    files: string,
    showDeleteModal: boolean,
    fileIndex: number
  }
}

export class AlertFormModal extends React.Component<AlertFormModal.Props, AlertFormModal.State> {
  static defaultProps: Partial<AlertFormModal.Props> = {

  };

  constructor(props: AlertFormModal.Props, context?: any) {
    super(props, context);
    this.state ={
        addAlert: this.props.addAlert,
        editAlert: this.props.editAlert,
        progressCountVar: 0,
        openFileModal: false,
        showPopUp: false,
        files: '',
        showDeleteModal: false,
        fileIndex: -1
      }

  }


  showFiles = (files: string) => {
    this.setState({ openFileModal: true, files: files });
  };

  handleChange= (value:any, name:any) =>{
    let formState = this.getFormState();

    formState[name] = value;
    if (formState.practice && formState.practice[0] === 'all' && formState.practice.length > 1) {
      formState.practice.splice(0, 1);
    } else if (formState.practice && formState.practice.length > 1 && formState.practice[formState.practice.length - 1] === 'all') {
      formState.practice.splice(0, formState.practice.length - 1);
    } else if (formState.practice && formState.practice.length === 0) {
      formState.practice.push("all");
    }

    if(formState.department && formState.department.indexOf(0) !== -1){
      formState.department = [0];
    }

    this.setState(formState);
  };

  closeFilesModal = () => {
    this.setState({ openFileModal: false, files: '' });
  };

  deleteFiles = () =>{
    const { action } = this.props;
    let field:any = this.state;
    let fields = field[action];
    let index = field.fileIndex;
    if (index > -1) {
     fields.fileLinks.splice(index, 1);
    }
    let updatedState:any={};
    updatedState[this.props.action] = fields;
    updatedState.openFileModal= false;
    updatedState.showDeleteModal= false;
    updatedState.fileIndex= -1;
    this.setState(updatedState);
  }

  showDeleteModal = (index: number) => {
    this.setState({ ...this.state, showDeleteModal: true, fileIndex: index})
  };

  hideDeleteModal = () => {
    this.setState({ ...this.state, showDeleteModal: false, fileIndex: -1})
  }

  getFormState = () => {
    let formState:any = this.state;
    return formState[this.props.action];
  };

  validateEmail(emailString: string): boolean {
    const emails = emailString.split(',').map(email => email.trim());
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    return emails.every(email => re.test(String(email).toLowerCase()));
  }

  saveForm = (event: any) => {
    let formStates = this.getFormState();
    let formState = {...formStates};
    let failureEmails = formState.failure.split(',').map(email => email.trim());
    let notificationEmails = formState.notification.split(',').map(email => email.trim());
    let isEmail = true;

    // Validate failure emails
    failureEmails.forEach((email) => {
      if(!this.validateEmail(email)) {
        isEmail = false;
      }
    });

    // Validate notification emails
    notificationEmails.forEach((email) => {
      if(!this.validateEmail(email)) {
        isEmail = false;
      }
    });

    if (isEmail) {
      formState.practice = (formState.practice && Array.isArray(formState.practice) && formState.practice.length) ? 
        formState.practice.join(",") : formState.practice;
      formState.fileLinks = (formState.fileLinks && Array.isArray(formState.fileLinks) && formState.fileLinks.length) ? 
        formState.fileLinks.join(",") : formState.fileLinks;
      
      // Clean up email lists by removing extra spaces
      formState.failure = failureEmails.join(',');
      formState.notification = notificationEmails.join(',');

      if (formState.query && formState.query.toLowerCase().startsWith('select')) {
        if (this.props.action === 'addAlert')
          this.props.saveAlert(formState);
        else
          this.props.updateAlert(formState);
      } else {
        alert('Please enter select Query only!');
      }
    } else {
      alert('Please enter valid email addresses (comma-separated)');
    }
  };

  onChange = (e:any) => {
    let formState = this.getFormState();
    formState[e.target.name] = e.target.value;
    this.setState(formState);
  };

  onChangeCheckbox=(e, data)=> {
    let formState = this.getFormState();
    formState[data.name]=data.checked;
    this.setState(formState);
  };

  onUploadStart = (file: any, next: any) => {
    progressCountVar++;
    this.setState({ progressCountVar: progressCountVar });
    next(file);
  };

  onUploadError = (e:any) => {
    console.log('onUploadError',e);
    alert("Upload file error.");
    progressCountVar--;
  };

  onFinish = async (e:any) => {
    let formState = this.getFormState();
    if(!formState.fileLinks) {
      formState.fileLinks = []
    }
    formState.fileLinks.push(e.signedUrl.split('?').shift());

    progressCountVar--;
    this.setState({
      progressCountVar: progressCountVar
    });
  };

  deleteIcon = () => {
    this.onChange({ target: { type: 'image', name: 'fileLinks', value: '' }});
  };

  testQuery =(e: any)=>{
    e.preventDefault();
    let states = this.getFormState();
    if(states.query && states.practice && states.query.toLowerCase().startsWith('select')){
      this.props.testAlertQuery({query:states.query, practice:states.practice, department:states.department,name:states.name, isCdpDbAlert: states.isCdpDbAlert});
      this.setState({ showPopUp: true });
    } else {
      alert('Please select practice and enter select Query only!');
    }
  };

  closePopUpModal =()=>{
    this.setState({ showPopUp: false });
  };
  getQueryFields = (queryString) => {

    const fieldRegex =/(?:\s*(?=\w+\.|.*as\s+|distinct\s+|)(\*|\w+)(?=\s*(?=,|from)))/ig
    let match;
    const columns:any = [];
    while (match = fieldRegex.exec(queryString)) {
      if (match) {
        columns.push(match[1]);
      }
    }

    let obj:any  = [{text:'',value:''}];
    if(columns && columns.length) {
      columns.forEach((field) => {
        obj.push({ text: field, value: field })
      })
    }
    return  obj;
  }

  render() {
    const { action, isLoad, testAlert, practices, practiceList,practiceOTDList, notificationGroups, carrierList,
      carrierPrimaryList, patientValidationStatus } = this.props;
    const {openFileModal, files, showPopUp, showDeleteModal} = this.state;
    const listItem: any = [];
    let field:any = this.state;
    let fields = field[action];
    fields.practice = typeof fields.practice === 'string' ? fields.practice.split(','): fields.practice;
    fields.department = typeof fields.department === 'string' ? fields.department.split(',').map(Number): fields.department;
    fields.fileLinks && fields.fileLinks.map((files: string, index: number) => {
      const fileArr: any = files.split('/');
      const fileName = fileArr[fileArr.length - 1];
      listItem.push(<List.Item>{fileName}
        <Icon className="red-text float-right" key={index} name="trash"
              onClick={() => this.showDeleteModal(index)}/>
      </List.Item>);

    })
    return (
        <Modal open={true}
               onClose={this.props.hideForm}
               centered
               closeIcon
               size={'small'} >
          <Modal.Header>{action === 'addAlert' ? 'Add Alert':'Edit Alert'}</Modal.Header>
          <Modal.Content>
            { isLoad && <Loading />}
            <Form className='formStyle' onSubmit={this.saveForm}>
              <Form.Group widths='equal'>
                <Form.Field >
                  <label>Name</label>
                  <Input placeholder='Name' name='name' value={fields.name ? fields.name : ""} onChange={this.onChange} required />
                </Form.Field>
                <Form.Field>
                  <label>Practice</label>
                  <Dropdown multiple selection fluid
                            onChange={ (e, a) => this.handleChange(a.value, 'practice') }
                            placeholder='Select Practice'
                            value={fields.practice ? fields.practice : []} required
                            options={practices} />
                </Form.Field>
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field >
                  <label>Notification</label>
                  <Input 
                    type='text'
                    placeholder='email1@cdp.dental, email2@cdp.dental'
                    name='notification'
                    value={fields.notification ? fields.notification : ""}
                    onChange={this.onChange}
                    required
                  />
                  <small className="form-text text-muted">Multiple emails can be added using commas</small>
                </Form.Field>
                <Form.Field>
                  <label>Failure</label>
                  <Input
                    placeholder='email1@cdp.dental, email2@cdp.dental'
                    name='failure'
                    value={fields.failure ? fields.failure : ""}
                    onChange={this.onChange}
                    required
                  />
                  <small className="form-text text-muted">Multiple emails can be added using commas</small>
                </Form.Field>
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field>
                  <label>How to Fix</label>
                  <TextArea value={fields.howToFix ? fields.howToFix : ""} name='howToFix' onChange={this.onChange} required />
                </Form.Field>
                <Form.Field>
                  <label>Description</label>
                  <TextArea value={fields.description ? fields.description : ""} name='description' onChange={this.onChange} required />
                </Form.Field>
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field>
                  <label>Upload File (Upload Only mp4)
                  </label>
                  <ReactS3Uploader
                    multiple
                    signingUrl={`${apiHost}alert/s3AlertFiles`}
                    signingUrlMethod="GET"
                    className='form-group'
                    accept='.mp4'
                    uploadRequestHeaders={{
                      'x-amz-acl': 'public-read'
                    }}
                    // contentDisposition="auto"
                    preprocess={(file:any , next:any ) => this.onUploadStart(file, next)}
                    onError={this.onUploadError}
                    onFinish={(res:any) => this.onFinish(res)}
                  />
                  {(progressCountVar>0) ? <Loading /> :""}
                  {(fields.fileLinks && fields.fileLinks.length) ? <p className="mt10 mb5">Total Files: {fields.fileLinks.length} <span onClick={()=>{this.showFiles(fields.fileLinks)}}>(Click to Show)</span></p>:""}
                  {listItem.length > 0 && <List divided relaxed>
                    {listItem}
                  </List>}
                </Form.Field>
                <Form.Field>
                  <label>Alert Enable/Disable</label>
                  <Checkbox checked={fields.isActive } name='isActive' onChange={this.onChangeCheckbox} required />
                </Form.Field>
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field>
                  <label>Email to Regional Manager</label>
                  <Checkbox
                    checked={fields.emailToRegionalManager }
                    name='emailToRegionalManager'
                    onChange={this.onChangeCheckbox} required
                  />
                </Form.Field>
                <Form.Field>
                  <label>Email to Practice Manager</label>
                  <Checkbox
                    checked={fields.emailToPracticeManager }
                    name='emailToPracticeManager'
                    onChange={this.onChangeCheckbox} required
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field>
                  <label>Department</label>
                  <Dropdown selection fluid
                            multiple={true}
                            value={fields.department ?  fields.department : []}
                            onChange={ (e, a) => this.handleChange(a.value, 'department') }
                            placeholder='Select Department'
                            name='department'
                            options={notificationGroups}/>
                </Form.Field>
                <Form.Field>
                  <label>Frequency</label>
                  <Dropdown selection fluid
                            value={fields.frequency ? fields.frequency : ''}
                            onChange={ (e, a) => this.handleChange(a.value, 'frequency') }
                            placeholder='Select Frequency'
                            name='frequency'
                            options={frequency}/>
                </Form.Field>
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field>
                  <label>Start Time</label>
                  <Dropdown selection fluid
                            value={fields.startTime ? fields.startTime : ''}
                            onChange={ (e, a) => this.handleChange(a.value, 'startTime') }
                            placeholder='Select Start Time'
                            name='startTime'
                            options={timeArray}/>
                </Form.Field>
                <Form.Field>
                  <label>End Start</label>
                  <Dropdown selection fluid
                            defaultValue={fields.endTime ? fields.endTime : ''}
                            onChange={ (e, a) => this.handleChange(a.value, 'endTime') }
                            placeholder='Select End Time'
                            name='endTime'
                            options={timeArray}/>
                </Form.Field>
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Field>
                    <label>Other DB Enable/Disable</label>
                    <Checkbox checked={fields.isOtherDB } name='isOtherDB' onChange={this.onChangeCheckbox} required />
                </Form.Field>
                <Form.Field>
                  <label>Previous Month View</label>
                  <Checkbox checked={fields.prevMonth } name='prevMonth' onChange={this.onChangeCheckbox} required />
                </Form.Field>
                {
                  fields.prevMonth ?<Form.Field>
                    <label>Column Filter</label>
                    <Dropdown selection fluid
                              onChange={ (e, a) => this.handleChange(a.value, 'prevMonthNameFilter') }
                              placeholder='Select Date Filter'
                              defaultValue={fields.prevMonthNameFilter ? fields.prevMonthNameFilter : ''}
                              name='prevMonthNameFilter'
                              disabled={!fields.query}
                              options={this.getQueryFields(fields.query ? fields.query : "")}/>
                  </Form.Field>:""

                }
            </Form.Group>
              <Form.Field>
                <label>Query</label>
                <TextArea value={fields.query ? fields.query : ""} name='query' onChange={this.onChange} required/>
              </Form.Field>
              <Form.Field>
                <label>Tab32 Query</label>
                <TextArea value={fields.queryTab32 ? fields.queryTab32 : ""} name='queryTab32' onChange={this.onChange}/>
              </Form.Field>
              <Form.Group widths='equal'>
              <Form.Field>
                  <label>Is Call Centre  Alert</label>
                  <Checkbox checked={fields.isCallCentreAlert } name='isCallCentreAlert' onChange={this.onChangeCheckbox} required />
                </Form.Field>
              <Form.Field>
                <label>Is CDP DB Alert</label>
                <Checkbox checked={fields.isCdpDbAlert } name='isCdpDbAlert' onChange={this.onChangeCheckbox} required />
              </Form.Field>
              </Form.Group>
              <Form.Field>
                {action === 'addAlert' ? <Button primary>Save</Button> : <Button primary>Update</Button> }
                <Button secondary className='float-right' onClick={this.testQuery}>Test Query</Button>
              </Form.Field>
            </Form>
            {openFileModal && <ViewFileModal closeModal={this.closeFilesModal} files={files}/>}
            {(!isLoad && showPopUp) && <PopUpModal closeModal={this.closePopUpModal} data={testAlert} popUpHeader='Query Result' practices={practiceList} practicesOTD={practiceOTDList} carrierList={carrierList}
                                                   carrierPrimaryList={carrierPrimaryList} patientValidationStatus={patientValidationStatus}/>}
            {showDeleteModal && <DeleteModal hideDeleteModal={this.hideDeleteModal} deleteAlertHandle={this.deleteFiles} popupHeader="Delete Video"/>}
          </Modal.Content>
        </Modal>
    );
  }
}
